import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';

import RootApp from '@app/index';

const container = document.getElementById('root');

if (!container) {
  throw new Error("Root container 'root' not found in the DOM.");
}

const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <RootApp/>
  </React.StrictMode>
);

reportWebVitals();
