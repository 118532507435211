import React, {createContext, useState, useEffect, useCallback} from 'react';
import {fetchUserInfo} from '@pages/shared/api';

type channelState = {
  link: string;
  name: string;
}

type userInfoState = {
  id: number;
  email: string;
  password: string;
  name: string;
  channels: Array<channelState>;
  balance: number;
  bot_count: string;
  task_count: any;
  task_count_today: number;
  daily_task_limit: number;
  auto_task_count: any;
  telegram: string;
  subscribe_type: any;
  subscribe_end: any;
  registration_date: string;
  last_activity_date: string;
  ip: string;
  headers: string;
  token: string;
  token_close: string;
  permission: string;
  status: string;
  auto_task_limit: number;
  telebottoken: string;
  channel_limit: number;
  is_auto_delete: number;
  is_auto_subscribe: number;
}

const userInfoInitialState: userInfoState = {
  id: 0,
  email: '-',
  password: '',
  name: '-',
  channels: [],
  balance: 0,
  bot_count: '0',
  task_count: '0',
  task_count_today: 0,
  daily_task_limit: 0,
  auto_task_count: 0,
  telegram: '-',
  subscribe_type: '-',
  subscribe_end: '-',
  registration_date: '-',
  last_activity_date: '-',
  ip: '-',
  headers: '-',
  token: '-',
  token_close: '-',
  permission: '-',
  status: '-',
  auto_task_limit: 0,
  telebottoken: '-',
  channel_limit: 0,
  is_auto_delete: 0,
  is_auto_subscribe: 0,
}

export const AuthContext = createContext({
  isAuthenticated: false,
  isAdmin: false,
  userInfo: userInfoInitialState,
  handleChangeUserInfo: (updates: any) => {
  },
  login: () => {
  },
  logout: () => {
  },
  activeAuthMenu: '',
  handleOpenRegistrationWindow: () => {
  },
  handleOpenLoginWindow: () => {
  },
  handleOpenResetWindow: () => {
  },
  handleCloseWindow: () => {
  },
});

export const AuthProvider = ({children}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token'));
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<userInfoState>(userInfoInitialState);
  const [activeAuthMenu, setActiveAuthMenu] = useState<string>('');


  const logout = useCallback(() => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    setUserInfo(userInfoInitialState);
    setIsAdmin(false);
  }, []);

  const handleChangeUserInfo = (updates: any) => {
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      ...updates, // Обновление нескольких полей
    }));
  };

  const handleFetchUserInfo = useCallback(
    async (token: string | null) => {
      if (!token) {
        logout();
        return;
      }

      const response = await fetchUserInfo(token); // Передача токена в API
      if (response.data?.status) {
        setUserInfo(response.data);
        if (response.data.permission) {
          setIsAdmin(response.data.permission === 'admin');
        }
      } else {
        logout();
      }
    },
    [logout]
  );

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsAuthenticated(true);
        await handleFetchUserInfo(token);
      }
      setLoading(false);
    };

    fetchData().then();
  }, [handleFetchUserInfo]);

  const login = useCallback(() => {
    setIsAuthenticated(true);
    const token: string | null = localStorage.getItem('token');
    handleFetchUserInfo(token).then();
  }, [handleFetchUserInfo]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleOpenRegistrationWindow = () => {
    setActiveAuthMenu('Registration');
  };

  const handleOpenLoginWindow = () => {
    setActiveAuthMenu('Login');
  };

  const handleOpenResetWindow = () => {
    setActiveAuthMenu('Reset');
  };

  const handleCloseWindow = () => {
    setActiveAuthMenu('');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdmin,
        userInfo,
        handleChangeUserInfo,
        login,
        logout,
        activeAuthMenu,
        handleOpenRegistrationWindow,
        handleOpenLoginWindow,
        handleOpenResetWindow,
        handleCloseWindow,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
