/* Здесь происходит определение главных блоков страницы */
import React, {useContext} from 'react';
import {Routing} from "@pages/index";
import {ThemeContext} from './Provider/ThemeContext';
import {NotificationProvider} from "@app/Provider/Notification/NotificationContext";
import {Provider} from "react-redux";
import {store} from "@app/store";
import {ThemeProvider} from '@app/Provider/ThemeContext';
import {AuthProvider} from '@app/Provider/AuthContext';

import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

export default function RootApp() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`App ${theme}`}>
      <Provider store={store}>
        <ThemeProvider>
          <AuthProvider>
            <NotificationProvider>
              <Routing/>
            </NotificationProvider>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

