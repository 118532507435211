import React, {createContext, useState, useEffect, useCallback, memo} from "react";
import {HandySvg} from "handy-svg";
import ImageUtils from "@components/imageUtils";
import "./NotificationContext.css";

const images = ImageUtils.importAllImages(require.context("@assets/context/notification", false, /\.(svg)$/));
export const NotificationContext = createContext();

const labelNotification = {
  error: "Ошибка",
  success: "Успех",
  info: "Инфо",
  warning: "Внимание",
};

const NotificationWindow = memo(({
                                   notificationType,
                                   notificationText,
                                   notificationUrl,
                                   isClosing,
                                   onClose,
                                   onMouseEnter,
                                   onMouseLeave
                                 }) => {
  const handleClick = useCallback(() => {
    if (notificationUrl) {
      window.open(notificationUrl, "_blank");
    }
  }, [notificationUrl]);

  return (
    <div
      className={`notification-window ${notificationType} ${isClosing ? "closing" : ""}`}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{cursor: notificationUrl ? "pointer" : "default"}} // Условие для курсора
    >
      <div className="notification-window--content">
        <HandySvg src={images[`${notificationType}.svg`]} className="currentColor mr-5"/>
        <b className="mr-5">{labelNotification[notificationType]}: </b>
        {notificationText}
      </div>
      <HandySvg
        src={images["close.svg"]}
        className="notification-window--close mr-5"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      />
    </div>
  );
});

export const NotificationProvider = ({children}) => {
  const [notificationType, setNotificationType] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationUrl, setNotificationUrl] = useState("");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [timer, setTimer] = useState(null);


  const closeNotificationWithAnimation = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 300); // Время анимации
  }, []);

  const openNotification = useCallback((data, type) => {
    setNotificationType(type);
    setNotificationText(data.content);
    setNotificationUrl(data.clickUrl);
    setIsNotificationOpen(true);
    setIsClosing(false);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      closeNotificationWithAnimation();
    }, 5000);

    setTimer(newTimer);
  }, [closeNotificationWithAnimation, timer]);

  const closeNotification = useCallback(() => {
    clearTimeout(timer);
    closeNotificationWithAnimation();
  }, [timer, closeNotificationWithAnimation]);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (e.target.closest('.notification-window')) {
        e.preventDefault();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const showError = useCallback((content, clickUrl = '') => openNotification({
    content,
    clickUrl
  }, "error"), [openNotification]);
  const showWarning = useCallback((content, clickUrl = '') => openNotification({
    content,
    clickUrl
  }, "warning"), [openNotification]);
  const showInfo = useCallback((content, clickUrl = '') => openNotification({
    content,
    clickUrl
  }, "info"), [openNotification]);
  const showSuccess = useCallback((content, clickUrl = '') => openNotification({
    content,
    clickUrl
  }, "success"), [openNotification]);

  const handleMouseEnter = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
  }, [timer]);

  const handleMouseLeave = useCallback(() => {
    const newTimer = setTimeout(() => {
      closeNotificationWithAnimation();
    }, 5000);

    setTimer(newTimer);
  }, [closeNotificationWithAnimation]);

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  return (
    <NotificationContext.Provider
      value={{openNotification, closeNotification, showError, showWarning, showSuccess, showInfo}}
    >
      {children}
      {isNotificationOpen && (
        <NotificationWindow
          notificationType={notificationType}
          notificationText={notificationText}
          notificationUrl={notificationUrl}
          isClosing={isClosing}
          onClose={closeNotification}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
    </NotificationContext.Provider>
  );
};
