import React, {useContext, lazy, Suspense, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import {AuthContext} from "@app/Provider/AuthContext";

const Profile = lazy(() => import('@pages/Profile/Profile'));
const ProfileSettings = lazy(() => import('@pages/Profile/Options/ProfileSettings'));
const Wallet = lazy(() => import('@pages/Profile/Options/Wallet'));
const Tasks = lazy(() => import('@pages/Tasks/Tasks'));
const Home = lazy(() => import('@pages/Home/Home'));
const Support = lazy(() => import('@pages/Support/Support'));
const Partnership = lazy(() => import('@pages/Partnership/Partnership'));
const About = lazy(() => import('@pages/About'));
const AdminPanel = lazy(() => import('@pages/AdminPanel/AdminPanel'));

export const Routing = () => {
  const {isAuthenticated, isAdmin} = useContext(AuthContext);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ref = params.get('ref');

    if (ref) {
      localStorage.setItem('referral', ref);
    }
  }, []);

  return (
    <Router>
      <Suspense fallback={<div>Загрузка...</div>}>
        <Routes>
          <Route path="/admin" element={isAdmin ? <AdminPanel/> : <Navigate to="/"/>}/>
          <Route path="/" element={isAuthenticated ? <Profile/> : <Home/>}/>
          <Route path="/about" element={!isAuthenticated ? <About/> : <Navigate to="/"/>}/>
          <Route path="/partnership" element={<Partnership/>}/>
          <Route path="/settings" element={isAuthenticated ? <ProfileSettings/> : <Navigate to="/"/>}/>
          <Route path="/wallet" element={isAuthenticated ? <Wallet/> : <Navigate to="/"/>}/>
          <Route path="/tasks" element={isAuthenticated ? <Tasks/> : <Navigate to="/"/>}/>
          <Route path="/support" element={isAuthenticated ? <Support/> : <Navigate to="/"/>}/>
          <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
      </Suspense>
    </Router>
  );
};
