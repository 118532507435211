import {configureStore} from '@reduxjs/toolkit';
import {useSelector, useDispatch, useStore} from 'react-redux';

type State = {
  activeAuthMenu: string;
}

export type closeAuthMenuAction = {
  type: 'CLOSE_AUTH_MENU';
}

export type openRegistrationMenuAction = {
  type: 'OPEN_REGISTRATION';
}

type Action = closeAuthMenuAction | openRegistrationMenuAction;

const initialState = {
  activeAuthMenu: '',
}

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case 'CLOSE_AUTH_MENU':
      return {
        ...state,
        activeAuthMenu: '',
      }
    case 'OPEN_REGISTRATION':
      return {
        ...state,
        activeAuthMenu: 'Registration',
      }
    default:
      return state;
  }
}

export const store = configureStore({
  reducer: reducer,
})

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector = useSelector.withTypes<AppState>();
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppStore = useStore.withTypes<typeof store>();
